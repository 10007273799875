// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/grudges/grudge-darker.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/grudges/grudge-dark.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/grudges/grudge-green.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/images/grudges/grudge-gold.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/images/grudges/grudge-white.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".image-text{display:flex;width:100%;background:#fff;justify-content:center;align-items:center}.image-text.grudge-darker{background:#122a15}.image-text.grudge-darker h1,.image-text.grudge-darker h2{color:#fff}.image-text.grudge-darker:after,.image-text.grudge-darker:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.image-text.grudge-dark{background:#3d6136}.image-text.grudge-dark h1,.image-text.grudge-dark h2{color:#fff}.image-text.grudge-dark:after,.image-text.grudge-dark:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.image-text.grudge-green{background:#cae0c7}.image-text.grudge-green:after,.image-text.grudge-green:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.image-text.grudge-gold{background:#eccc8a}.image-text.grudge-gold:after,.image-text.grudge-gold:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.image-text.grudge-white{background:#fff}.image-text.grudge-white:after,.image-text.grudge-white:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}.image-text.grudge-dark .content,.image-text.grudge-dark h2{color:#fff}.image-text .image-cell{width:55%}.image-text .text-cell{width:45%}.image-text .image{width:100%;border-radius:4px}.image-text .text-cell{display:flex;flex-direction:column;padding:50px 5% 50px 12%;box-sizing:border-box}.image-text.text-left .text-cell{padding:50px 12% 50px 5%}.image-text h2{width:100%;margin:0 0 20px;color:#3d6136;font-weight:400;font-size:35px;font-family:\"Bodoni-Hand-Regular\",\"helvetica\",\"verdana\",sans-serif;line-height:40px}.image-text .content{margin:0 0 20px;color:#3a382e;font-size:18px;line-height:28px}@media screen and (max-width:940px){.image-text{flex-direction:column;padding:0 20px;box-sizing:border-box}.image-text.reversed{flex-direction:column-reverse}.image-text .image-cell,.image-text .text-cell{width:100%}.image-text .text-cell{padding:30px 0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
