<template lang="pug">
.image-text(:class="[{ 'reversed': align === 'bottom', 'text-left': align === 'left' }, grudeClass(color)]")
  .image-cell(v-if="['right', 'top', 'bottom', null].includes(align)")
    img.image(:src="block.img")
  .text-cell
    h2(v-if="block.h2" :style="headerTextColor(block.header_text_color)") {{ block.h2 }}
    .content(v-if="block.content" v-html="block.content")
    nuxt-link.button.gold(v-if="block.action_link" :to="block.action_link") {{ block.action_text }}
  .image-cell(v-if="align === 'left'")
    img.image(:src="block.img")
</template>

<script>
import { grudgeMixin, textColorMixin } from '@/mixins/colorMixin';
import { breakpointMixin } from '@/mixins/breakpointMixin';

export default {
  name: 'ImageText',
  mixins: [grudgeMixin, textColorMixin, breakpointMixin],
  props: {
    block: {
      type: Object,
      default: () => {},
    },
    color: {
      type: String,
      default: 'ffffff',
    },
  },
  computed: {
    align() {
      // mobile: returns top bottom
      // desktop: returns left right
      if (this.isMobile) {
        return this.block.img_align_mobile;
      }

      return this.block.header_text_align;
    },
  },
};
</script>

<style lang="scss">
.image-text {
  @include grudge-backgrounds();

  display: flex;
  width: 100%;
  background: $white;
  justify-content: center;
  align-items: center;

  &.grudge-dark {

    h2, .content {
      color: $white;
    }
  }

  .image-cell {
    width: 55%;
  }

  .text-cell {
    width: 45%;
  }

  .image {
    width: 100%;
    border-radius: $radius-small;
  }

  .text-cell {
    display: flex;
    flex-direction: column;
    padding: 50px 5% 50px 12%;
    box-sizing: border-box;
  }

  &.text-left {
    .text-cell {
      padding: 50px 12% 50px 5%;
    }
  }

  h2 {
    width: 100%;
    margin: 0 0 20px 0;
    color: $hunterGreen;
    font-weight: 400;
    font-size: 35px;
    font-family: $fontBodoni;
    line-height: 40px;
  }

  .content {
    margin: 0 0 20px 0;
    color: $blackOlive;
    font-size: 18px;
    line-height: 28px;
  }
  @media screen and (max-width: $breakpoint-md) {
    flex-direction: column;
    padding: 0 20px;
    box-sizing: border-box;

    &.reversed {
      flex-direction: column-reverse;
    }

    .image-cell, .text-cell {
      width: 100%;
    }

    .text-cell {
      padding: 30px 0;
    }
  }
}
</style>
